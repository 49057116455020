<template>
  <div>
    <!-- <div class="d-flex justify-content-center mb-3">
      <b-spinner label="Loading..."></b-spinner>
    </div> -->
    <!-- Row Start -->
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Country</h3>
            </div>
            <div class="card-toolbar">
              <b-button variant="outline-primary" v-b-modal.add-country-modal
                >Add Country</b-button
              >
            </div>
          </div>

          <div class="card-body body-fluid">
            <b-table striped hover :items="countries" :fields="fields">
              <template #cell(actions)="row">
                <b-button @click="ShowEditModal(row.item.id)" variant="primary"
                  >Edit</b-button
                >
                <b-button
                  @click="deletecountry(row.item.id)"
                  class="ml-2"
                  variant="danger"
                  >Delete</b-button
                >
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <!-- Row End -->
    <!-- Add Modal -->
    <!-- <form class="form" novalidate="novalidate" id="add-country-form"> -->
    <b-modal
      id="add-country-modal"
      ref="modal"
      @show="resetModal"
      @hidden="resetModal"
      @ok="addcountry"
      title="Add Country"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Country Name" label-for="name-input">
              <b-form-input
                id="name-input"
                v-model="form.name"
                placeholder="Singapore"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group label="Code">
              <b-form-input
                id="name-input"
                placeholder="SG"
                v-model="form.code"
                required
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal
      id="edit-country-modal"
      ref="modal"
      @hidden="resetModal"
      @ok="updatecountry"
      title="Edit Country"
    >
      <form ref="editform" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Country Name" label-for="name-input-1">
              <b-form-input
                id="name-input-1"
                v-model="form.name"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group label="Code">
              <b-form-input
                id="name-input"
                v-model="form.code"
                required
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <!-- </form> -->
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
    ></loading>
  </div>
</template>
<script>
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// FormValidation plugins
// import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
// import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
// import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

// import KTUtil from "@/assets/js/components/util";
export default {
  data() {
    return {
      //   items: [
      //     { age: 40, first_name: "Dickerson", last_name: "Macdonald" },
      //     { age: 21, first_name: "Larsen", last_name: "Shaw" },
      //     { age: 89, first_name: "Geneva", last_name: "Wilson" },
      //     { age: 38, first_name: "Jami", last_name: "Carney" },
      //   ],
      isLoading: false,
      fullPage: true,
      countries: [],
      fields: ["name", "code", "actions"],
      form: {
        name: null,
        code: null,
        id: 0,
      },
      nameState: null,
      submittedNames: [],
    };
  },
  components: {
    Loading,
  },
  created() {
    this.getAllCountries();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Settings" },
      { title: "Country" },
    ]);
  },
  methods: {
    checkErrors() {
      if (this.form.name && this.form.code) {
        return true;
      }
      this.errors = [];

      if (!this.form.name) {
        this.errors.push("Name is required.");
      }
      if (!this.form.code) {
        this.errors.push("Code is required.");
      }
      for (let field of this.errors) {
        this.makeToastVariant("danger", field, "Error");
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    checkEditFormValidity() {
      const valid = this.$refs.editform.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.form.name = null;
      this.form.code = null;
      this.nameState = null;
    },
    makeToastVariant(variant = null, text, title) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    addcountry(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Exit when the form isn't valid
      // if (!this.checkFormValidity()) {
      //   return;
      // }
      if (this.checkErrors() == true) {
        this.isLoading = true;
        ApiService.post("country", this.form)
          .then(() => {
            
            this.getAllCountries();
            this.$nextTick(() => {
              this.$bvModal.hide("add-country-modal");
              this.makeToastVariant(
                "success",
                "Country added successfully",
                "Success"
              );
            });
          })
          .catch(() => {
            this.isLoading = false;
            // context.commit(SET_ERROR, response.data.errors);
            // context.commit(SET_ERROR, response);
          });
      }
    },
    updatecountry(bvModalEvt) {
      bvModalEvt.preventDefault();
      
      // Exit when the form isn't valid
      // if (!this.checkEditFormValidity()) {
      //   return;
      // }
      if (this.checkErrors() == true) {
        this.isLoading = true;
        ApiService.update("country", this.form.id, this.form)
          .then(() => {
            this.getAllCountries();
            this.$nextTick(() => {
              this.makeToastVariant(
                "info",
                "Country updated successfully",
                "Success"
              );
              this.$bvModal.hide("edit-country-modal");
            });
          })
          .catch(() => {
            this.isLoading = false;
            // context.commit(SET_ERROR, response.data.errors);
            // context.commit(SET_ERROR, response);
          });
      }
    },
    getAllCountries() {
      this.isLoading = true;
      ApiService.get("getcountries")
        .then(({ data }) => {
          this.countries = data;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    ShowEditModal(id) {
      this.isLoading = true;
      ApiService.get("country", id)
        .then(({ data }) => {
          this.form = data;
          this.isLoading = false;
          this.$bvModal.show("edit-country-modal");
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    deletecountry(id) {
      this.isLoading = true;
      ApiService.delete("country", id)
        .then(() => {
          this.makeToastVariant(
            "warning",
            "Country deleted successfully",
            "Success"
          );
          this.getAllCountries();
        })
        .catch(() => {
          this.isLoading = false;
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
  },
};
</script>
